import Component from '../lib/Component'
import Swiper from 'swiper'

export default class ToolSlider extends Component {
    mount() {
        new Swiper(this.element, {
            slidesPerView: 1,
            pagination: {
                el: '.js-tool-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                }
            },
        });
    }
}

