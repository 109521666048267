const UAParser = require('ua-parser-js');
import Mediaquery from '../services/Mediaquery'
import Window from '../services/Window'

type Handler = (event: Event) => void
type Test = () => Element
export type Selector =  string | Test
type UAParser = typeof UAParser;
/*export type Selector = string | () => HTMLElement*/

/**
 * Component Class
 */
export default class Component {
  children: { [key: string]: Component } = {}
  handlers: Map<Handler,string> = new Map()
  container: HTMLElement = document.body
  element: HTMLElement
  selector: Selector
  mediaQuery: Mediaquery
  window: Window
  parser: UAParser

  /**
   * constructor
   */
  constructor(selector?: Selector) {
    this._mount = this._mount.bind(this)
    this._unmount = this._unmount.bind(this)
    this.changeMediaquery = this.changeMediaquery.bind(this)

    this.mediaQuery = Mediaquery.create()
    this.window = Window.create()

    this.mediaQuery.on(Mediaquery.CHANGE, this.changeMediaquery)
    this.parser = new UAParser();
    if(selector) {
      this.attach(selector)
    }
  }

  append(component: Component, selector?: Selector) {
    // for ie. obj.constructor.nameがとれない
    let getClassName = (obj:Component): string => {
      if (obj.constructor.name) {
        return obj.constructor.name
      }
      const regex = new RegExp(/^\s*function\s*(\S*)\s*\(/)
      getClassName = obj => obj.constructor.toString().match(regex)![1]
      return getClassName(obj)
    }

    const className:string = getClassName(component)
    this.children[className] = component
    /*this[className] = component*/
    if (selector) {
      component.attach(selector)
    }
    return this
  }

  get(name: string): Component {
    return this.children[name]
  }
  /*
  destroy() {
    Object.keys(this.children).map(key => {
      const child = this.children[key]
      child.destroy()
      delete this[key]
    })
    this.children = []
    this.destructor()
    this.removeAllEventListeners()
    this._unmount()
  }*/

  emit(type: string) {
    // FIXME: polyfill しっかり
    let event = null
    if (typeof Event === 'function') {
      event = new Event(type)
    } else {
      event = document.createEvent('Event')
      event.initEvent(type, true, true)
    }
    this.element.dispatchEvent(event)
  }

  on(type: string, handler: Handler) {
    this.handlers.set(handler, type)
    this.element.addEventListener(type, handler)
  }

  off(type: string, handler: Handler) {
    if (handler) {
      this.handlers.delete(handler)
      this.element.removeEventListener(type, handler)
    } else {
      this.removeAllEventListeners(type)
    }
  }

  removeAllEventListeners(type?: string) {
    const remove: Handler[] = []
    this.handlers.forEach((t:string,h:Handler) => {
      if (!type || (
          type && t === type
      )) {
        this.element.removeEventListener(t, h)
        remove.push(h)
      }
    })
    remove.map(r => {
      this.handlers.delete(r)
    })
  }

  attach(selector: Selector) {
    this.selector = selector
    if (!this.selector) {
      throw new ReferenceError('selector is not found.')
    }
    this.element = this._select()
    if (!this.element) {
      throw new ReferenceError(`${selector}: element is not found.`)
    }
    if (this.element) {
      this._mount()
    }
  }

  _mount() {
    this.mount()
  }

  _unmount() {
    this.mediaQuery.removeListener(Mediaquery.CHANGE, this.changeMediaquery)
    this.unmount()
  }


  /**
   * select
   */
  _select(): any {
    if (typeof this.selector === 'function') {
      return this.selector()
    }
    return this.container && this.container.querySelector(this.selector)
  }

  setContainer(container: HTMLElement) {
    this.container = container
  }

  /**
   * mount
   */
  mount() {
  }

  /**
   * view
   */
  view() {

  }

  /**
   * willUnmount
   */
  willUnmount() {
  }

  /**
   * unmount
   */
  unmount() {
  }

  /**
   * destructor
   */
  destructor() {
  }

  /**
   * changeMediaquery
   */
  changeMediaquery() {
  }

}
