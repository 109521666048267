import './polyfill'
import Body from './components/Body'
import PageScroll from "./components/PageScroll"
import ToolSlider from "./components/ToolSlider"
import {configure} from './config'
import ComponentManager from './lib/ComponentManager'
import {domLoaded} from './lib/promisfy'



const applicationSequence = async () => {
    configure()
    await domLoaded()
    const _ComponentManager = new ComponentManager({
        'body': Body,
        "[href^='#']": PageScroll,
        '.js-tool-slider': ToolSlider,
    })
    _ComponentManager.start()
}


applicationSequence()