import Component from '../lib/Component'
import gsap from 'gsap'

export default class PageScroll extends Component {
    _scroll(selector: string) {
        let target:HTMLElement | null
        if(selector == "#"){
            target = document.body;
        }else{
            target = document.querySelector(selector);
        }
        if(target == null){
            target = document.body;
        }
        const top = target.getBoundingClientRect().top + window.scrollY
        const scroll = {y: window.scrollY}
        gsap.to(scroll,{
            duration: 0.8,
            y: top,
            onUpdate: () => {
                window.scrollTo(0, scroll.y)
            }
        })

    }

    mount() {
        this.on('click', e => {
            const target = this.element.getAttribute('href');
            if(target){
                this._scroll(target)
            }
            e.preventDefault()
            return true
        })
    }
}

