export type ConfigType = {}

const config:ConfigType = {
  MEDIA_QUERY: {
    phone: 'screen and (max-width: 899px)',
    desktop: 'screen and (min-width: 900px)',
  },
}

declare global {
  interface Window {
    config: ConfigType,
  }
}

window.config = config;

const configure = () => {
  window.config = config;
}

export {
  configure,
}

