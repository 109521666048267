import Component from '../lib/Component'

export default class Body extends Component {
    mount() {
        const browser:string = this.parser.getBrowser().name;
        document.documentElement.dataset.browser = browser;
    }

}

