/* @flow */
import Component from './Component'

type NodeList = () => NodeListOf<Element>
type HTMLCollectionList = () => HTMLCollection
type NodeSelector = NodeList | HTMLCollectionList
type Selector = string | NodeSelector
type ComponentCreator = {new(): Component;}
type Handler = (event: Event) => void

/**
 * ComponentArray Class
 */
export default class ComponentArray {
  components: Component[] = []
  _componentCreator: ComponentCreator
  _selector: Selector
  _elements: NodeListOf<Element> | HTMLCollection

  constructor(componentCreator: ComponentCreator, selector: Selector) {
    this._componentCreator = componentCreator
    if (selector) {
      this.attach(selector)
    }
  }

  _select() {
    return typeof this._selector === 'function'
        ? this._selector()
        : document.querySelectorAll(this._selector)
  }

  on(type: string, handler: Handler) {
    this.components.map(c => {
      c.on(type, handler)
    })
  }

  off(type: string, handler: Handler) {
    this.components.map(c => {
      c.off(type, handler)
    })
  }

  push(args:Component[]) {
    return this.components.push(...args)
  }

  filter(args:(value:Component,index:number) => boolean) {
    return this.components.filter(args)
  }
  /*
   every(args:Component[]) {
     return this.components.every(...args)
   }
   */
  map(args:(value:Component,index:number) => Component[] | void) {
    return this.components.map(args)
  }

  willUnmount() {
    this.components.map(c => {
      c.willUnmount()
    })
  }

  /*destroy() {
    this.components.map(c => {
      c.destroy()
    })
    this.components = []
  }*/

  attach(selector: Selector) {
    this._selector = selector
    this._elements = this._select()
    const elements = Array.from(this._elements)
    elements.map(e => {
      const component = new this._componentCreator()
      component.attach(() => e)
      this.components.push(component)
    })
  }

  view() {
    this.components.map(c => {
      c.view()
    })
  }

}
