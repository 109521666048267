import EventEmitter from "events"
import imagesLoaded from 'imagesloaded';

export default class Loading extends EventEmitter{
  static COMPLETE = 'complete'
  static PROGRESS = 'progress'
  static FAIL = 'fail'

  loader: any
  target: ImagesLoaded.ElementSelector = 'body'
  imageTotalCount:number
  _loaded:boolean = false

  constructor(selector?:Element){
    super();
    if(selector){
      this.target = selector;
    }
  }

  start(){
    this.loader = imagesLoaded(this.target,() => {this._loaded = true;this.emit(Loading.COMPLETE, this);})
    this.loader.on('progress',this.emit(Loading.PROGRESS, this))
    this.loader.on('fail',this.emit(Loading.FAIL, this))
    this.imageTotalCount = this.loader.images.length;
  }

  get loaded(): boolean {
    return this._loaded
  }
}

