/* @flow */
import Component from './Component'
import ComponentArray from './ComponentArray'
import Loader from '../loader/Loader'

type ComponentCreator = {new(): Component;}
type ComponentMap = { [selector: string]: ComponentCreator }

export default class ComponentManager {
  _components: ComponentArray[] = []
  loader: Loader = new Loader()
  _map : ComponentMap

  constructor(map: ComponentMap) {
    this._map = map
  }

  _construct() {
    Object.keys(this._map).map(key => {
      const ComponentClass = this._map[key]
      const c = new ComponentArray(ComponentClass, key)
      this._components.push(c)
    })
  }

  _complete() {
    this._components.map(c => {
      c.view()
    })
  }

  start() {
    this._construct()
    this.loader.on(Loader.COMPLETE, () => {
      this._complete()
    })
    this.loader.start();
  }


}

