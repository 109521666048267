import EventEmitter from 'events'

let singleton: Mediaquery

export default class Mediaquery extends EventEmitter {
    screenMode: string
    vars: {[key: string]: string; }

    static CHANGE: string = 'change'

    static create(vars = window.config.MEDIA_QUERY ) {
        if (singleton) {
            if (process.env.NODE_ENV !== 'production') {
                // console.log(singleton.constructor.name, singleton.listenerCount('change'))
            }
            return singleton
        }
        singleton = new Mediaquery(vars || {})
        singleton.setMaxListeners(0)
        return singleton
    }

    constructor(vars: {[key: string]: string; }) {
        super()
        this.vars = vars
        this.screenMode = ''

        Object.keys(this.vars).map(key => {
            const val:string = this.vars[key]
            const mql = window.matchMedia(val)
            mql.addListener(mql => {
                if (mql.matches) {
                    this.changeScreenMode(key)
                }
            })
            if (mql.matches) {
                this.screenMode = key
            }
        })
    }

    changeScreenMode(current: string) {
        if (this.screenMode !== current) {
            this.screenMode = current
            this.emit(Mediaquery.CHANGE, this)
        }
    }

    isPhone() {
        return this.screenMode === 'phone'
    }

    isDesktop() {
        return !this.isPhone()
    }
}

